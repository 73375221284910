var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Control sizing"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeControlSize) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Set the form-control text size using the ")]), _c('code', [_vm._v("size")]), _c('span', [_vm._v(" prop to ")]), _c('code', [_vm._v("sm")]), _c('span', [_vm._v(" or ")]), _c('code', [_vm._v("lg")]), _c('span', [_vm._v(" for small or large respectively.")])]), _c('div', [_c('b-form-select', {
    attrs: {
      "options": _vm.options,
      "size": "sm"
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }), _c('b-form-select', {
    staticClass: "mt-1",
    attrs: {
      "options": _vm.options,
      "size": "lg"
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }), _c('div', {
    staticClass: "mt-1"
  }, [_vm._v(" Selected: "), _c('strong', [_vm._v(_vm._s(_vm.selected))])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }