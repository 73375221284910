var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Multiple select support"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeMultiple) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Enable multiple select mode by setting the prop ")]), _c('code', [_vm._v("multiple")]), _c('span', [_vm._v(", and control how many rows are displayed in the multiple select list-box by setting ")]), _c('code', [_vm._v("select-size")]), _c('span', [_vm._v(" to the number of rows to display. The default is to let the browser use its default (typically 4). ")])]), _c('b-form-select', {
    attrs: {
      "options": _vm.options,
      "multiple": "",
      "select-size": 4
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }), _c('div', {
    staticClass: "mt-1"
  }, [_vm._v(" Selected: "), _c('strong', [_vm._v(_vm._s(_vm.selected))])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }