var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('form-select-basic')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('form-select-standard')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('form-select-size')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('form-select-multiple')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('form-select-control-size')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('form-select-states')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }